import queryString from 'query-string';
import React from 'react';

import { QuestionsRealEstate } from '../components';
import SEO from '../components/seo';

const realEstateSatisfactionSurvey = (props) => {
  let param = queryString.parse(props.location.search);

  return (
    <>
      <SEO title="Pesquisa de Satisfação – Imobiliária" />
      <QuestionsRealEstate token={param.z} version={param.v} />
    </>
  );
};

export default realEstateSatisfactionSurvey;
